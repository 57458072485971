import React from 'react'
import BackToTop from 'react-back-to-top-button'

// import longlogo from '../assets/images/long-logo.png'

var days = [
  'Sundays!',
  'Mondays!',
  'Tuesdays!',
  'Wednesdays!',
  'Thursdays!',
  'Fridays!',
  'Saturdays!',
]
var day = new Date()
var today = days[day.getDay()]

const Footer = props => (
  <footer id="footer">
    <BackToTop showOnScrollUp showAt={100} speed={500} easing="easeInOutQuint">
      <span>scroll up</span>
    </BackToTop>
    <div className="footer-content inner">
    <div className="social">
        <ul className="icons">
          
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://medium.com/@sagarsatam"
              className="icon alt fa-medium"
            >
              <span className="label">Medium</span>
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.linkedin.com/in/satamsagar/"
              className="icon alt fa-linkedin"
            >
              <span className="label">LinkedIn</span>
            </a>
          </li>
          {/* <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://dribbble.com/sagarsatam"
              className="icon alt fa-dribbble"
            >
              <span className="label">Dribbble</span>
            </a>
          </li> */}
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.instagram.com/sagar.satam/"
              className="icon alt fa-instagram"
            >
              <span className="label">Instagram</span>
            </a>
          </li> 
        </ul>
      </div>
      <div className="hm">Happy {today}</div>
      <div className="copyright">
        <p className="footer-copyright">© 2024 Sagar Satam</p>
      </div>
      {/* <div className="info-touch">
        <div className="keep-touch">keep in touch</div>
        <div className="hr-line" />
        <p className="email-text">sagar.satam25@gmail.com</p>
      </div> */}
    </div>

    {/* <div className="footer-bottom inner"> */}
      {/* <p className="footer-copyright">© 2020 Sagar Satam</p> */}
      {/* <a className="footer-logo" href="/">
        <img src={longlogo} alt="" />
      </a> */}
    {/* </div> */}
  </footer>
)

export default Footer
