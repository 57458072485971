import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import logo from '../assets/images/logo2.png'
// import logo from '../assets/images/logo.png'


const Menu = props => (
  <nav id="menu">
    <div className="menu-inner">
      <div className="menu-logo">
        <Link to="/" onClick={props.onToggleMenu} className="logo">
          <img src={logo} alt="" />
        </Link>
      </div>
      <ul className="links">
        <li>
          <Link onClick={props.onToggleMenu} to="/#one">
            featured work
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/work">
            all my work
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/what-offer">
            my approach
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/aboutme">
            about me
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/say-hello">
            say hello :)
          </Link>
        </li>
        {/* <li>
          <Link onClick={props.onToggleMenu} to="/how-work">
            how i work
          </Link>
        </li> */}
        {/* <li>
          <ul className="sub-links">
            <li>
              <Link onClick={props.onToggleMenu} to="/aboutme">
                about me
              </Link>
            </li>
            <li>
              <Link onClick={props.onToggleMenu} to="/what-offer">
                what i offer
              </Link>
            </li>
            <li>
              <Link onClick={props.onToggleMenu} to="/my-thinking">
                my thinking
              </Link>
            </li>
            <li>
              <Link onClick={props.onToggleMenu} to="/say-hello">
                say hello :)
              </Link>
            </li>
          </ul>
        </li> */}
        <li className="social-links">
          <ul className="icons">
            
            <li>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://medium.com/@sagarsatam"
                className="icon alt fa-medium"
              >
                <span className="label">Medium</span>
              </a>
            </li>
            <li>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.linkedin.com/in/satamsagar/"
                className="icon alt fa-linkedin"
              >
                <span className="label">LinkedIn</span>
              </a>
            </li>
            {/* <li>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://dribbble.com/sagarsatam"
                className="icon alt fa-dribbble"
              >
                <span className="label">Dribbble</span>
              </a>
            </li> */}
            <li>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.instagram.com/sagar.satam/"
                className="icon alt fa-instagram"
              >
                <span className="label">Instagram</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
      <a
        className="close"
        onClick={props.onToggleMenu}
        href="javascript:void(0)"
      >
        Close
      </a>
    </div>
  </nav>
)

Menu.propTypes = {
  onToggleMenu: PropTypes.func,
}

export default Menu
