/* eslint-disable */

import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import logo from '../assets/images/logo2.png'
// import logo from '../assets/images/logo1.png'


const Header = props => (
  <header id="header" className="alt">
    <div className="inner">
      <Link to="/" className="logo">
        <img src={logo} alt="" />
      </Link>
      <nav>
        <a
          className="menu-link"
          onClick={props.onToggleMenu}
          href="javascript:;"
        />
      </nav>
    </div>
  </header>
)

Header.propTypes = {
  onToggleMenu: PropTypes.func,
}

export default Header
